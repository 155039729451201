<template>
  <div class="p-4">
    <!-- Individual user Information -->
    <div
      class="row row-cols-1"
      v-if="loginUserObj.role_id == 3 && getUserIndividualPoliceChecksAll"
    >
      <div class="col mb-4">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Profile</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <dl class="row">
                  <dt class="col-sm-3">Given name</dt>
                  <dd class="col-sm-9">
                    {{ getUserIndividualPoliceChecksAll.first_name }}
                  </dd>
                  <dt class="col-sm-3">Middle name</dt>
                  <dd class="col-sm-9">
                    {{ getUserIndividualPoliceChecksAll.middle_name }}
                  </dd>
                  <dt class="col-sm-3">Family name</dt>
                  <dd class="col-sm-9">
                    {{ getUserIndividualPoliceChecksAll.last_name }}
                  </dd>
                </dl>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <dl class="row">
                  <dt class="col-sm-3">Email</dt>
                  <dd class="col-sm-9">
                    {{ getUserIndividualPoliceChecksAll.email }}
                  </dd>
                  <dt class="col-sm-3">Mobile no</dt>
                  <dd class="col-sm-9">
                    {{ getUserIndividualPoliceChecksAll.mobile_no }}
                  </dd>
                  <dt class="col-sm-3">Date of birth</dt>
                  <dd class="col-sm-9">
                    {{ getUserIndividualPoliceChecksAll.date_of_birth }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="policeCheckList && policeCheckList.length > 0">
      <template v-for="policeCheck in policeCheckList">
        <div
          class="col-lg-4 mb-4"
          :key="policeCheck.id"
          v-if="
            policeCheck.sh_id == 1 ||
            policeCheck.sh_id == '' ||
            policeCheck.sh_id == null
          "
        >
          <div class="card">
            <div class="card-header">
              <h5 class="mb-0">Police check</h5>
            </div>
            <div class="card-body">
              <dl class="row">
                <dt class="col-sm-4">Ref.number</dt>
                <dd class="col-sm-8">{{ policeCheck.id }}</dd>
                <dt class="col-sm-4">Date of submitted</dt>
                <dd class="col-sm-8">
                  {{ policeCheck.created_at | dateConvertYYYYMMDD }}
                </dd>
                <dt class="col-sm-4">Check type</dt>
                <dd class="col-sm-8" v-if="policeCheck.product">
                  {{ policeCheck.product.name }}
                </dd>
                <dd class="col-sm-8" v-else></dd>

                <dt class="col-sm-4">Payment</dt>
                <dd class="col-sm-8" v-if="policeCheck.payment_ref">
                  <i class="far fa-check-circle text-success"></i>
                </dd>
                <dd class="col-sm-8" v-else>
                  <i class="far fa-times-circle text-danger"></i>
                </dd>
                <dt class="col-sm-4">Status</dt>
                <dd class="col-sm-8" v-if="policeCheck.status == 1">
                  <span class="badge badge-secondary">Draft</span>
                </dd>
                <dd class="col-sm-8" v-if="policeCheck.status == 2">
                  <span class="badge badge-primary">Submitted</span>
                </dd>
                <dd class="col-sm-8" v-if="policeCheck.status == 3">
                  <span class="badge badge-warning"
                    >More Information Required</span
                  >
                </dd>
                <dd class="col-sm-8" v-if="policeCheck.status == 4">
                  <span class="badge badge-info"
                    >Waiting For Police Review</span
                  >
                </dd>
                <dd class="col-sm-8" v-if="policeCheck.status == 5">
                  <span class="badge badge-success">Completed</span>
                </dd>
                <dd class="col-sm-8" v-if="policeCheck.status == 6">
                  <span class="badge badge-dark">Cancelled</span>
                </dd>
                <dd class="col-sm-8" v-if="policeCheck.status == 7">
                  <span class="badge badge-light">Ready to Check</span>
                </dd>
              </dl>
              <div class="row">
                <div class="col-12 text-right">
                  <button
                    class="btn btn-info btn-sm mr-2"
                    @click="fnViewApplication(policeCheck)"
                  >
                    View
                  </button>
                  <button
                    v-if="policeCheck.status == 1"
                    class="btn btn-warning btn-sm mr-2 text-white"
                    @click="fnEditApplication(policeCheck)"
                  >
                    Edit
                  </button>
                  <button
                    v-if="policeCheck.status == 5"
                    class="btn btn-success btn-sm text-white"
                    @click="fnDownloadCertificate(policeCheck)"
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import * as miscellaneousService from "../../services/miscellaneous.service";
export default {
  data() {
    return {
      loginUserObj: {},
      id: "",
      shId: 1,
      projectId: "",
      parentId: "", // This user belongs to Partner or Corporate manager
      customerId: "", // Customer Id
      checkId: "", // Product id
      stepId: "", // Police check
      mode: "new",
    };
  },
  computed: {
    ...mapGetters([
      "getUserIndividualPoliceChecksAll",
      "getProjectsListByUser",
    ]),

    policeCheckList: function () {
      if (
        this.getUserIndividualPoliceChecksAll &&
        this.getUserIndividualPoliceChecksAll.police_checks
      ) {
        return this.getUserIndividualPoliceChecksAll.police_checks;
      } else {
        return [];
      }
    },
    /**
     * Get User information from getProjectsListByUser
     */
    profileInformationCC: function () {
      if (
        this.getProjectsListByUser[0] &&
        this.getProjectsListByUser[0].profile_info
      ) {
        return this.getProjectsListByUser[0].profile_info;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchUserIndividualPoliceChecksAll",
      "fetchProjectsListByUser",
    ]),
    // View Police check Applications
    fnViewApplication(policeCheck) {
      this.$router.push({
        name: "PoliceCheckView",
        params: {
          userId: encodeDecodeService.encode(this.loginUserObj.id),
          id: encodeDecodeService.encode(policeCheck.id),
        },
      });
    },
    fnEditApplication(policeCheck) {
      this.$router.push({
        name: "PoliceCheckIUStepsEdit",
        params: {
          userId: encodeDecodeService.encode(this.loginUserObj.id),
          id: encodeDecodeService.encode(policeCheck.id),
        },
      });
    },
    // Download (view) final certificate
    fnDownloadCertificate: async function (policeCheck) {
      let url = "";
      url = await miscellaneousService.fnGetTempUrl(
        `${policeCheck.police_check_certificate_from_nss}`
      );
      window.open(url);
    },
  },
  created() {
    this.loginUserObj = authService.getUserFromToken();

    if (this.loginUserObj.role_id == 9) {
      this.customerId = this.loginUserObj.id;
      // Get Projects based on user id
      this.fetchProjectsListByUser(this.customerId);
    }

    if (this.loginUserObj.role_id == 3) {
      this.customerId = this.loginUserObj.id;
      // Get Projects based on user id
      this.fetchUserIndividualPoliceChecksAll(this.customerId);
    }
  },
};
</script>